export class EnvUtils {
  /**
   * Retrieve an environment variable from `process.env`. This looks for
   * the environment variable first with a `REACT_APP` prefix, then a uses
   * `STORYBOOK_` if one with `REACT_APP` was not found.
   *
   * @param name The name of the environment variable without prefixes.
   * @param defaultValue The default value to return.
   */
  public static get(name: string, defaultValue: string = "") {
    return (
      process.env[`REACT_APP_${name}`] ??
      process.env[`STORYBOOK_${name}`] ??
      defaultValue
    );
  }
}
