import { createContext } from "react";
import { SearchClient } from "algoliasearch/lite";

export interface SearchContextState {
  /**
   * The API key that the user can use for searching.
   */
  searchKey?: string;

  /**
   * The search client. This is initialised when a valid
   * search key is retrieved.
   */
  client?: SearchClient;
}

export const INITIAL_STATE: SearchContextState = {};

/**
 * This context provides the search key the current
 * user can use for searching.
 */
export const SearchContext = createContext<SearchContextState>(INITIAL_STATE);
