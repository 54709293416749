import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "UsersDrawer.title",
    defaultMessage: "User Management",
  },
  users: {
    id: "UsersDrawer.users",
    defaultMessage: "Users",
  },
  invites: {
    id: "UsersDrawer.invites",
    defaultMessage: "Invites",
  },
  verifications: {
    id: "UsersDrawer.verifications",
    defaultMessage: "Verifications",
  },
});
