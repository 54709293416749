export const CCCV_LIGHT_BLUE = "#3cb3df";
export const CCCV_DARK_BLUE = "#221c73";
export const BACKGROUND = "#eceff1";
export const TEXT_PRIMARY = "#374348";
export const TEXT_SECONDARY = "#757575";
export const TEXT_PRIMARY_CONTRAST = "#e0ebf2";
export const TEXT_SECONDARY_CONTRAST = "#c3c6c7";
export const LINK_DARK = "#3d6571";
export const FACEBOOK_BLUE = "#3f51b5";
export const GOOGLE_BLUE = "#4081ec";
export const SHADED = "#fafafa";
