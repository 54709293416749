import { createContext } from "react";

export interface ThemeContextState {
  /**
   * The theme type.
   */
  themeType: "light" | "dark";

  /**
   * Set the theme type.
   */
  setThemeType: (type: ThemeContextState["themeType"]) => void;
}

export const INITIAL_STATE: ThemeContextState = {
  themeType: "light",
  setThemeType: () => {},
};

/**
 * The theme context keeps track of the currently selected theme.
 */
export const ThemeContext = createContext<ThemeContextState>(INITIAL_STATE);
