import { useEffect, useState } from "react";
import deepequal from "fast-deep-equal";

import { useFirestore } from "../../contexts/firebase/FirebaseContext";

import { PathOrColReference, ColRef, ColSnapshot, SnapshotOptions } from "./utils";

/**
 * Creates and caches a query reference from a collection
 * path, or an existing `firestore.CollectionReference` object.
 *
 * @param path A path to a collection or a reference to a collection.
 * @returns `ref`
 */
export const useCollectionReference = <T>(
  path?: PathOrColReference<T> | null
): ColRef<T> | undefined => {
  const store = useFirestore();
  const [ref, setRef] = useState<ColRef<T>>();

  // Create reference
  useEffect(() => {
    if (!store) return;

    // Reset the ref if the path becomes empty
    if (!path) {
      setRef(undefined);
      return;
    }

    // Create new reference if path is different
    if (typeof path === "string") {
      if (path !== ref?.path) setRef(store.collection(path) as ColRef<T>);
    } else if (path.path !== ref?.path) {
      setRef(path);
    }

    // FIXME: eslint thinks the type parameter T is a dependency, disabling this rule to supress the warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, path, ref, setRef]);

  return ref;
};

/**
 * Retrieve and listen to documents in a collection or query.
 * A listener is created only when a valid path or reference
 * is given.
 *
 * Returns the following in an array:
 *  - The query snapshot when retrieved successfully. `undefined` otherwise.
 *  - A boolean representing the loading state of the query.
 *  - Error if an error occurred. `undefined` otherwise.
 *
 * @param path a path to a collection or a reference to a collection.
 * @returns `[snapshot, loading, error]`
 */
export const useCollection = <T>(
  path?: PathOrColReference<T> | null
): [ColSnapshot<T> | undefined, boolean, Error | undefined] => {
  const ref = useCollectionReference(path);

  const [loading, setLoading] = useState(Boolean(path));
  const [error, setError] = useState<Error>();
  const [snapshot, setSnapshot] = useState<ColSnapshot<T>>();

  // Listen for query updates
  useEffect(() => {
    // If there is no ref, reset data
    if (!ref?.onSnapshot) {
      setSnapshot(undefined);
      setLoading(false);
      setError(undefined);
      return;
    }

    let cancelled = false;
    setLoading(true);

    const unsubscribe = ref?.onSnapshot(
      (snapshot) => {
        if (cancelled) return;
        setSnapshot(snapshot);
        setLoading(false);
        setError(undefined);
      },
      (error) => {
        if (cancelled) return;
        setLoading(false);
        setError(error);
      }
    );

    return () => {
      cancelled = true;
      if (unsubscribe) unsubscribe();
    };
  }, [ref, setLoading, setSnapshot, setError]);

  return [snapshot, loading, error];
};

/**
 * Retrieve and listen to a collection's data from Firestore.
 * A listener is created only when a valid path or reference
 * is given.
 *
 * Returns the following in an array:
 *  - The collection data. `undefined` otherwise.
 *  - A boolean representing the loading state of the collection snapshot.
 *  - Error if an error occurred. `undefined` otherwise.
 *
 * @param path A path or a reference to a collection.
 * @param options Snapshot options.
 * @returns `[data, loading, error]`
 */
export const useCollectionData = <T>(
  path?: PathOrColReference<T> | null,
  options?: SnapshotOptions
): [T[] | undefined, boolean, Error | undefined] => {
  const [snapshot, loading, error] = useCollection(path);
  const [ops, setOps] = useState(options);
  const [data, setData] = useState<T[]>();

  // Update options if they change
  useEffect(() => {
    if (!deepequal(options, ops)) setOps(options);
  }, [options, ops, setOps]);

  // If the snapshot changes, extract the data
  useEffect(() => {
    setData(snapshot?.docs.map((snap) => snap.data(ops)));
  }, [setData, snapshot, ops]);

  return [data, loading, error];
};
