import { useState, useCallback } from "react";

/**
 * This hook creates the state and callbacks required to
 * save a HTMLElement anchor for a menu.
 *
 * @param openCallback Callback to be called when the open menu function is executed.
 * @param closeCallback Callback to be called when the close menu function is executed.
 * @returns `[menuAnchor, openMenu, closeMenu]`
 */
export const useMenuAnchor = (
  openCallback?: (event: React.MouseEvent<HTMLElement>) => void,
  closeCallback?: () => void
): [
  HTMLElement | null,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void
] => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  /**
   * Open the menu.
   */
  const openMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      openCallback?.(event);
      setAnchor(event.currentTarget);
    },
    [openCallback, setAnchor]
  );

  /**
   * Close the menu.
   */
  const closeMenu = useCallback(() => {
    closeCallback?.();
    setAnchor(null);
  }, [closeCallback, setAnchor]);

  return [anchor, openMenu, closeMenu];
};

/**
 * This hook creates the state and callbacks required to
 * save a HTMLElement anchor for a menu with a piece of state.
 *
 * @param defaultData Initial value for the data.
 * @param openCallback Callback to be called when the open menu function is executed.
 * @param closeCallback Callback to be called when the close menu function is executed.
 * @returns `[data, menuAnchor, openMenu, closeMenu]`
 */
export const useMenuAnchorWithState = <T = unknown>(
  defaultValue?: T,
  openCallback?: (event: React.MouseEvent<HTMLElement>, data: T) => void,
  closeCallback?: () => void
): [
  T | undefined,
  HTMLElement | null,
  (event: React.MouseEvent<HTMLElement>, data: T) => void,
  () => void
] => {
  const [data, setData] = useState(defaultValue);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  /**
   * Open the menu.
   */
  const openMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, data: T) => {
      openCallback?.(event, data);
      setAnchor(event.currentTarget);
      setData(data);
    },
    // FIXME: eslint thinks the type parameter T is a dependency, disabling this rule to supress the warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openCallback, setData, setAnchor]
  );

  /**
   * Close the menu.
   */
  const closeMenu = useCallback(() => {
    closeCallback?.();
    setAnchor(null);
  }, [closeCallback, setAnchor]);

  return [data, anchor, openMenu, closeMenu];
};
