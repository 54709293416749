import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import {
  useCurrentUser,
  AuthorisationState,
  useCurrentUserClaims,
} from "../state/FirebaseAuthState";

import { useDocumentData } from "../hooks/firebase";
import { useLogError } from "../hooks/log";

import { useAuth } from "../contexts/firebase/FirebaseContext";

import { UserProfile } from "../../../types";

/**
 * Get the current user's profile from Firestore.
 */
export const useCurrentUserProfile = (): [
  UserProfile | undefined,
  boolean,
  Error | undefined
] => {
  const user = useCurrentUser();
  const userId = user ? `users/${user.uid}` : null;
  const [profile, loading, error] = useDocumentData<UserProfile>(userId);
  useLogError(
    "Error retrieving current user profile.",
    "hooks.auth.useCurrentUserProfile",
    error
  );
  return [profile, loading, error];
};

/**
 * Returns a function that will sign out the current user.
 */
export const useSignOut = () => {
  const navigate = useNavigate();
  return useCallback(() => {
    navigate("/secure/exit");
  }, [navigate]);
};

/**
 * Create a function that refreshes the current user's claims.
 */
export const useRefreshClaims = () => {
  const auth = useAuth();
  const setPermissions = useSetRecoilState(AuthorisationState);

  // Return callback to update claims
  return useCallback(async () => {
    const user = auth?.currentUser;
    if (!user) return;
    const { claims } = await user.getIdTokenResult(true);
    setPermissions(claims);
  }, [auth, setPermissions]);
};

/**
 * Check if the current user has the specified role.
 *
 * @param role The role to check.
 */
export const useHasRole = (role: string) => {
  const claims = useCurrentUserClaims();
  return (claims?.roles as string[])?.includes(role);
};
