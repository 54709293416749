import { defineMessages } from "react-intl";

export default defineMessages({
  contact: {
    id: "Footer.contact",
    defaultMessage: "Contact",
  },
  terms: {
    id: "Footer.terms",
    defaultMessage: "Terms",
  },
  privacy: {
    id: "Footer.privacy",
    defaultMessage: "Privacy",
  },
});
