import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "VisitorsDrawer.title",
    defaultMessage: "Visitors",
  },
  tracker: {
    id: "VisitorsDrawer.tracker",
    defaultMessage: "Visitor tracker",
  },
  locations: {
    id: "VisitorsDrawer.locations",
    defaultMessage: "Visit locations",
  },
  trace: {
    id: "VisitorsDrawer.trace",
    defaultMessage: "Visitor trace",
  },
});
