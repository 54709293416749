import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { DRAWER_WIDTH_SPACING } from "../../../components/core/AppDrawer";

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      transition: theme.transitions.create("margin-left", {
        easing: theme.transitions.easing.easeInOut,
        duration: 150,
      }),
    },
    contentShift: {
      marginLeft: theme.spacing(DRAWER_WIDTH_SPACING),
    },
  })
);
