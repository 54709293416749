import { defineMessages, MessageDescriptor } from "react-intl";

const msg = defineMessages({
  owner: {
    id: "role.owner",
    defaultMessage: "System owner.",
  },
  admin: {
    id: "role.admin",
    defaultMessage: "Admin privileges to manage the system.",
  },
  visitors: {
    id: "role.visitors",
    defaultMessage: "Access to sign visitors in and out of the locations.",
  },
  visitorsManage: {
    id: "role.visitorsManage",
    defaultMessage: "Access to log previous visits and manage visit locations.",
  },
  visitorsTrace: {
    id: "role.visitorsTrace",
    defaultMessage: "Access to run contact tracing reports on visitor records.",
  },
});

export enum UserRole {
  OWNER = "owner",
  ADMIN = "admin",
  VISITORS = "visitors",
  VISITORS_MANAGE = "visitors:manage",
  VISITORS_TRACE = "visitors:trace",
}

interface RoleDefinition {
  name: UserRole;
  description: MessageDescriptor;
}

export const OWNER_ROLE: RoleDefinition = {
  name: UserRole.OWNER,
  description: msg.owner,
};

export const ADMIN_ROLE: RoleDefinition = {
  name: UserRole.ADMIN,
  description: msg.admin,
};

export const VISITORS_ROLE: RoleDefinition = {
  name: UserRole.VISITORS,
  description: msg.visitors,
};

export const VISITORS_MANAGE_ROLE: RoleDefinition = {
  name: UserRole.VISITORS_MANAGE,
  description: msg.visitorsManage,
};

export const VISITORS_TRACE_ROLE: RoleDefinition = {
  name: UserRole.VISITORS_TRACE,
  description: msg.visitorsTrace,
};

export const ROLES = [
  ADMIN_ROLE,
  VISITORS_ROLE,
  VISITORS_MANAGE_ROLE,
  VISITORS_TRACE_ROLE,
];
