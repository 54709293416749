import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { version } from "../../../version.json";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import msg from "./messages";
import useStyles from "./styles";

export interface FooterProps {}

const YEAR = new Date().getFullYear();

/**
 * Displays the necessary footer links and notices.
 */
export const Footer: FC<FooterProps> = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography color="inherit" variant="body2" component="div">
        &copy; {YEAR}{" "}
        <Link
          className={classes.link}
          component={RouterLink}
          to="/"
          title="CCCV Community"
        >
          CCCV Community
        </Link>{" "}
        v{version}
      </Typography>
      <Typography variant="body2" component="div">
        <Link
          className={classes.link}
          component={RouterLink}
          to="/contact"
          title="contact"
        >
          <FormattedMessage {...msg.contact} />
        </Link>
        <span className={classes.spacer}>•</span>
        <Link
          className={classes.link}
          component={RouterLink}
          to="/terms"
          title="terms"
        >
          <FormattedMessage {...msg.terms} />
        </Link>
        <span className={classes.spacer}>•</span>
        <Link
          className={classes.link}
          component={RouterLink}
          to="/privacy"
          title="privacy"
        >
          <FormattedMessage {...msg.privacy} />
        </Link>
        <span className={classes.spacer}>•</span>
        <Link
          className={classes.link}
          href="https://cccv.org.au"
          title="Chinese Christian Church of Victoria"
        >
          cccv.org.au
        </Link>
      </Typography>
    </footer>
  );
};
