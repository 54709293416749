import { defineMessages } from "react-intl";

export default defineMessages({
  settings: {
    id: "AccountMenu.settings",
    defaultMessage: "Account Settings",
  },
  signOut: {
    id: "AccountMenu.signOut",
    defaultMessage: "Sign Out",
  },
  privacy: {
    id: "AccountMenu.privacy",
    defaultMessage: "Privacy Policy",
  },
  terms: {
    id: "AccountMenu.terms",
    defaultMessage: "Terms Of Use",
  },
});
