import { defineMessages } from "react-intl";
import SystemIcon from "@material-ui/icons/Settings";

import { ADMIN_ROLE } from "../../constants";

import { Application } from "./types";

const msg = defineMessages({
  name: {
    id: "SystemApp.name",
    defaultMessage: "System",
  },
  description: {
    id: "SystemApp.description",
    defaultMessage: "Logs and settings",
  },
});

export const SystemApp: Application = {
  id: "system",
  name: msg.name,
  description: msg.description,
  icon: SystemIcon,
  grantAccess: (claims) => (claims?.roles as string[]).includes?.(ADMIN_ROLE.name),
};
