import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "../../../constants";

import { DocumentTitleState } from "../../../state/DocumentTitleState";

export interface DocumentTitleProps {}

/**
 * Set the document title using `react-helmet-async` using the
 * value in DocumentTitleState.
 */
export const DocumentTitle: FC<DocumentTitleProps> = () => {
  const title = useRecoilValue(DocumentTitleState);
  return (
    <Helmet>
      <title>
        {title === APP_NAME || title.length === 0
          ? APP_NAME
          : `${title} - ${APP_NAME}`}
      </title>
    </Helmet>
  );
};
