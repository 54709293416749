import { DateUtils } from "./DateUtils";
import { TextUtils } from "./TextUtils";

const EMAIL_REGEX = /^[\w.-]+@[\w]+(\.[\w]+)+$/;
const NAME_REGEX = /^[A-Za-z -]+$/;
const NAME_NUMBER_REGEX = /^[\w -]+$/;
const MOBILE_REGEX = /^04\d{2} ?\d{3} ?\d{3}$/;
const PHONE_REGEX = /^\d{4} ?\d{4}$/;

export class ValidateInputUtils {
  /**
   * Returns an error message if the given input is not a valid email.
   */
  public static invalidEmail(input: string, name: string = "email") {
    if (!input) return `${TextUtils.capitalise(name)} is required.`;
    if (!EMAIL_REGEX.test(input)) return `Invalid ${name}.`;
    return "";
  }

  /**
   * Returns an error message if the given input is empty or contains
   * illegal characters for a person's name.
   *
   * @param input The input to validate.
   * @param name The name of the field, used in the error message.
   */
  public static invalidName(
    input: string,
    options?: {
      fieldName?: string;
      allowNumbers?: boolean;
    }
  ) {
    const { fieldName = "name", allowNumbers } = options ?? {};
    if (!input) return `${TextUtils.capitalise(fieldName)} is required.`;
    if (!(allowNumbers ? NAME_NUMBER_REGEX : NAME_REGEX).test(input))
      return `${TextUtils.capitalise(fieldName)} contains invalid characters.`;
    return "";
  }

  /**
   * Returns an error message if the given input is empty or does not
   * match the mobile number mask 04xx xxx xxx.
   *
   * @param input The input to validate.
   * @param name The name of the field, used in the error message.
   */
  public static invalidMobile(input: string, name: string = "mobile number") {
    if (!input) return `${TextUtils.capitalise(name)} is required.`;
    if (!MOBILE_REGEX.test(input))
      return `Invalid ${name}. Please use format 04xx xxx xxx.`;
    return "";
  }

  /**
   * Returns an error message if the given input is empty or does not
   * match the phone number mask xxxx xxxx.
   *
   * @param input The input to validate.
   * @param name The name of the field, used in the error message.
   */
  public static invalidPhone(input: string, name: string = "phone number") {
    if (!input) return `${TextUtils.capitalise(name)} is required.`;
    if (!PHONE_REGEX.test(input))
      return `Invalid ${name}. Please use format xxxx xxxx.`;
    return "";
  }

  /**
   * Returns an error message if the given input is not a valid
   * mobile number, phone number or email.
   *
   * @param input The input to validate.
   * @param name The name of the field, used in the error message.
   */
  public static invalidContact(input: string, name: string = "contact") {
    const mobileResult = this.invalidMobile(input, name);
    const phoneResult = this.invalidPhone(input, name);
    const emailResult = this.invalidEmail(input, name);
    if (mobileResult && phoneResult && emailResult)
      return `Invalid ${name}. Must be a phone number or email.`;
    return "";
  }

  /**
   * Returns an error message if the given input is not a valid entry
   * date for a visit. An entry date must not be in the future.
   *
   * @param input The input to validate.
   * @param now The current date to compare to.
   */
  public static invalidVisitEntryDate(input?: Date | null, now: Date = new Date()) {
    if (!input) return `Entry date is required.`;
    if (input > now) return `Entry date cannot be in the future.`;
    return "";
  }

  /**
   * Returns an error message if the given exit date is not a valid
   * exit date for a visit. An exit date cannot be in the future, and
   * must be after the given entry date if provided.
   *
   * @param exitDate The exit date to validate.
   * @param entryDate An entry date to use for validation.
   * @param now The current date to use.
   */
  public static invalidVisitExitDate(
    exitDate?: Date | null,
    entryDate?: Date | null,
    now: Date = new Date()
  ) {
    if (!exitDate) return `Exit date is required.`;
    if (entryDate)
      if (exitDate <= entryDate) return `Exit date must be after entry date.`;
    if (exitDate > DateUtils.getDayEnd(entryDate ?? now))
      return `Exit date cannot be on a different day.`;
    return "";
  }

  /**
   * Returns an error message if the given capacity is a positive number.
   *
   * @param input The input to validate.
   */
  public static invalidCapacity(input: string) {
    const capacity = parseInt(input);
    if (isNaN(capacity)) return `Capacity must be a valid number.`;
    if (capacity <= 0) return `Capacity must be a positive number.`;
    return "";
  }

  /**
   * Given some input, redact its contents by obscuring
   * the middle characters of the input according to the
   * following conditions:
   *
   * If the text is shorter than or equal to 6 characters, all
   * characters be redacted.
   *
   * If the text is longer than 6 characters, all middle characters
   * will be redacted except for:
   *   - The first two characters
   *   - The last two characters, unless the text is longer than 9
   *     characters, in which case the last three characters will
   *     not be redacted
   *   - Whitespace
   *   - @ character
   *   - . character
   *
   * @param input The input value to redact.
   */
  public static redact(input: string): string {
    const chars = Array.from(input);

    if (chars.length <= 6)
      return chars.map((char) => (/\d/.test(char) ? "x" : "*")).join("");

    const startOffset = 2;
    const endOffset = chars.length - (chars.length > 8 ? 3 : 2);

    return Array.from(input)
      .map((char, index) => {
        if (char === " ") return " ";
        if (char === "@") return "@";
        if (char === ".") return ".";
        if (index >= startOffset && index < endOffset)
          return /\d/.test(char) ? "x" : "*";
        return char;
      })
      .join("");
  }
}
