export class TextUtils {
  /**
   * Return the appropriate singular or plural version of the
   * word based on the given count.
   *
   * If no `plural` is given, it is automatically assumed that
   * a `s` can be appended for its plural form.
   *
   * @param count The count.
   * @param word The singular version of the word.
   * @param plural The pluralised version of the word.
   */
  public static plural(count: number, word: string, plural = `${word}s`): string {
    return count === 1 ? word : plural;
  }

  /**
   * Concatenates the count and the word, putting the word in
   * the appropriate plural form using the `pluralise` function.
   *
   * @param count The count.
   * @param word The singular version of the word.
   * @param plural The pluralised version of the word.
   */
  public static pluralWithCount(
    count: number,
    word: string,
    plural = `${word}s`
  ): string {
    return `${count} ${TextUtils.plural(count, word, plural)}`;
  }

  /**
   * Capitalise the first letter of the given string.
   *
   * @param word The word to capitalise.
   */
  public static capitalise(word: string): string {
    return (word[0] || "").toUpperCase() + word.slice(1);
  }
}
