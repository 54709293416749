import firebase from "firebase/app";

export type DocRef<T> = firebase.firestore.DocumentReference<T>;
export type ColRef<T> = firebase.firestore.CollectionReference<T>;
export type Query<T> = firebase.firestore.Query<T>;

export type DocSnapshot<T> = firebase.firestore.DocumentSnapshot<T>;
export type ColSnapshot<T> = firebase.firestore.QuerySnapshot<T>;
export type QuerySnapshot<T> = firebase.firestore.QuerySnapshot<T>;

export type SnapshotOptions = firebase.firestore.SnapshotOptions;

export type PathOrDocReference<T = unknown> = string | DocRef<T> | null;
export type PathOrColReference<T = unknown> = string | ColRef<T> | null;

/**
 * The type of query operation.
 */
export type QueryOpType =
  | "where"
  | "orderBy"
  | "limit"
  | "limitToLast"
  | "startAt"
  | "startAfter"
  | "endBefore"
  | "endAt";

/**
 * An operation that can be applied to a Firestore query.
 */
export type QueryOp =
  | WhereOp
  | OrderByOp
  | LimitOp
  | LimitToLastOp
  | StartAtOp
  | StartAfterOp
  | EndBeforeOp
  | EndAtOp;

export interface QueryOpBase<T extends any[] = any[]> {
  op: QueryOpType;
  args: T;
}

export interface WhereOp extends QueryOpBase {
  op: "where";
  args: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.WhereFilterOp,
    any
  ];
}

export interface OrderByOp extends QueryOpBase {
  op: "orderBy";
  args: [
    string | firebase.firestore.FieldPath,
    firebase.firestore.OrderByDirection?
  ];
}

export interface LimitOp extends QueryOpBase {
  op: "limit";
  args: [number];
}

export interface LimitToLastOp extends QueryOpBase {
  op: "limitToLast";
  args: [number];
}

export interface StartAtOp<T = unknown> extends QueryOpBase {
  op: "startAt";
  args: [firebase.firestore.DocumentSnapshot<T>] | any[];
}

export interface StartAfterOp<T = unknown> extends QueryOpBase {
  op: "startAfter";
  args: [firebase.firestore.DocumentSnapshot<T>] | any[];
}

export interface EndBeforeOp<T = unknown> extends QueryOpBase {
  op: "endBefore";
  args: [firebase.firestore.DocumentSnapshot<T>] | any[];
}

export interface EndAtOp<T = unknown> extends QueryOpBase {
  op: "endAt";
  args: [firebase.firestore.DocumentSnapshot<T>] | any[];
}

/**
 * This function takes in a query and applies a list of operations to the query.
 *
 * @param ref The query root reference.
 * @param operations A list of operations to apply to the query.
 */
export const generateQuery = <T>(
  ref: ColRef<T> | Query<T>,
  operations: (QueryOp | undefined | null)[]
): Query<T> => {
  let query = ref;
  for (const operation of operations) {
    switch (operation?.op) {
      case "where": {
        query = query.where(...operation.args);
        break;
      }
      case "orderBy": {
        query = query.orderBy(...operation.args);
        break;
      }
      case "limit": {
        query = query.limit(...operation.args);
        break;
      }
      case "limitToLast": {
        query = query.limitToLast(...operation.args);
        break;
      }
      case "startAt": {
        query = query.startAt(...operation.args);
        break;
      }
      case "startAfter": {
        query = query.startAfter(...operation.args);
        break;
      }
      case "endAt": {
        query = query.endAt(...operation.args);
        break;
      }
      case "endBefore": {
        query = query.endBefore(...operation.args);
        break;
      }
    }
  }
  return query;
};
