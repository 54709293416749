export class DateUtils {
  /**
   * Create a date object that is set to 00:00:00 of the given date.
   *
   * @param date The day to get the start of.
   */
  public static getDayStart(date = new Date()) {
    const start = new Date(date);
    start.setHours(0, 0, 0);
    return start;
  }

  /**
   * Create a date object that is set to 23:59:59 of the given date.
   *
   * @param date The day to get the end of.
   */
  public static getDayEnd(date = new Date()) {
    const end = new Date(date);
    end.setHours(23, 59, 59);
    return end;
  }
}
