import { useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";

import { useDesktopBreakpoint } from "../../hooks/breakpoint";

import {
  AppBarColorState,
  FeatureBarTransparentState,
  AppDrawerOpenState,
} from "./atoms";

/**
 * Returns functions to open, close and toggle the
 * app drawer open state.
 *
 * @returns `[openDrawer, closeDrawer, toggleDrawer]`
 */
export const useAppDrawerState = (): [() => void, () => void, () => void] => {
  const setAppDrawerState = useSetRecoilState(AppDrawerOpenState);

  const openDrawer = useCallback(() => {
    setAppDrawerState(true);
  }, [setAppDrawerState]);

  const closeDrawer = useCallback(() => {
    setAppDrawerState(false);
  }, [setAppDrawerState]);

  const toggleDrawer = useCallback(() => {
    setAppDrawerState((x) => !x);
  }, [setAppDrawerState]);

  return [openDrawer, closeDrawer, toggleDrawer];
};

/**
 * This hook forces the drawer to open when
 * we are on a desktop breakpoint.
 *
 * @param disabled Disable the hook.
 */
export const useOpenDrawerOnDesktop = (disabled?: boolean) => {
  const desktop = useDesktopBreakpoint();
  const [openDrawer, closeDrawer] = useAppDrawerState();

  useEffect(() => {
    if (disabled) return;
    if (desktop) openDrawer();
    return () => {
      if (desktop) closeDrawer();
    };
  }, [disabled, desktop, openDrawer, closeDrawer]);
};

/**
 * Set the app bar color. Resets when the hook unmounts.
 *
 * @param color The color to set.
 */
export const useAppBarColor = (color: "light" | "dark") => {
  const setAppBarColor = useSetRecoilState(AppBarColorState);
  useEffect(() => {
    setAppBarColor(color);
    return () => {
      setAppBarColor("dark");
    };
  }, [setAppBarColor, color]);
};

/**
 * Set whether the feature bar is transparent. Resets when the hook unmounts.
 *
 * @param transparent
 */
export const useFeatureBarTransparent = (transparent: boolean) => {
  const setFeatureBarTransparent = useSetRecoilState(FeatureBarTransparentState);
  useEffect(() => {
    setFeatureBarTransparent(transparent);
    return () => {
      setFeatureBarTransparent(true);
    };
  }, [setFeatureBarTransparent, transparent]);
};

/**
 * Set the layout type to use. The layout type will be reset
 * to the default when the hook unmounts.
 *
 * @param type The UI layout type to use.
 */
export const useUILayout = (type: "default" | "feature") => {
  useAppBarColor(type === "default" ? "dark" : "light");
  useFeatureBarTransparent(type === "default");
};
