import {
  createMuiTheme,
  responsiveFontSizes,
  darken,
  lighten,
} from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

import { Color } from ".";

const themeConfig: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: Color.CCCV_LIGHT_BLUE,
      contrastText: Color.TEXT_PRIMARY_CONTRAST,
    },
    secondary: {
      main: Color.CCCV_DARK_BLUE,
      contrastText: Color.TEXT_PRIMARY_CONTRAST,
    },
    background: {
      default: Color.BACKGROUND,
    },
    text: {
      primary: Color.TEXT_PRIMARY,
      secondary: Color.TEXT_SECONDARY,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Raleway, Roboto, Helvetica, Arial, sans-serif",
    },
    fontWeightRegular: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        caption: {
          lineHeight: 1.5,
        },
        paragraph: {
          marginBottom: 6,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineHover: {
          "cursor": "pointer",
          "transition": "color 150ms ease-in-out",
          "color": Color.LINK_DARK,
          "&:hover": {
            textDecoration: "none",
            color: darken(Color.CCCV_LIGHT_BLUE, 0.2),
          },
          "&:active": {
            color: Color.CCCV_LIGHT_BLUE,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          paddingLeft: 18,
          paddingRight: 18,
        },
        containedPrimary: {
          "color": "white",
          "&:hover": {
            backgroundColor: lighten(Color.CCCV_LIGHT_BLUE, 0.1),
          },
        },
        containedSecondary: {
          color: "white",
        },
        textSecondary: {
          color: Color.TEXT_PRIMARY,
        },
        outlinedSecondary: {
          "color": Color.TEXT_PRIMARY,
          "borderColor": lighten(Color.TEXT_PRIMARY, 0.7),
          "&:hover": {
            borderColor: lighten(Color.TEXT_PRIMARY, 0.6),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          fontWeight: 400,
          backgroundColor: "#616161f0",
        },
        touch: {
          fontSize: "1em",
          fontWeight: 400,
        },
      },
    },
  },
};

export const theme = responsiveFontSizes(createMuiTheme(themeConfig));
