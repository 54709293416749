import React, { FC } from "react";
import { useRecoilValue } from "recoil";

import { FeatureBarTransparentState } from "../../../state/AppUIState";

import useStyles from "./styles";

export interface FeatureBarProps {
  /**
   * The height of the feature bar.
   *
   * @default 280
   */
  height?: number;
}

/**
 * A feature bar renders a coloured background with a theme color.
 * Components may be rendered on top of the background.
 *
 * This component's transparency can be controlled through the
 * `FeatureBarTransparentState` atom.
 */
export const FeatureBar: FC<FeatureBarProps> = (props) => {
  const transparent = useRecoilValue(FeatureBarTransparentState);
  const classes = useStyles({
    ...props,
    transparent,
  });
  return <div className={classes.featureBar}></div>;
};
