import React, { FC } from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";

import { AppDrawerOpenState } from "../../../state/AppUIState";

import { useDesktopBreakpoint } from "../../../hooks/breakpoint";

import useStyles from "./styles";

export interface AppDrawerContentProps {}

/**
 * Wrapper for the content to render next to an app drawer.
 * Adds margin to the content to ensure it's shown properly
 * on desktop devices when the drawer is open.
 *
 * Drawer state is taken from the `AppDrawerOpenState` atom.
 */
export const AppDrawerContent: FC<AppDrawerContentProps> = ({ children }) => {
  const classes = useStyles();
  const desktop = useDesktopBreakpoint();
  const open = useRecoilValue(AppDrawerOpenState);
  return (
    <div
      className={clsx(classes.content, {
        [classes.contentShift]: desktop && open,
      })}
    >
      {children}
    </div>
  );
};
