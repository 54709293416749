import { defineMessages } from "react-intl";
import VisitorsIcon from "@material-ui/icons/TransferWithinAStation";

import { VISITORS_ROLE } from "../../constants";

import { Application } from "./types";

const msg = defineMessages({
  name: {
    id: "VisitorsApp.name",
    defaultMessage: "Visitors",
  },
  description: {
    id: "VisitorsApp.description",
    defaultMessage: "Track visitors on premise",
  },
});

export const VisitorsApp: Application = {
  id: "visitors",
  name: msg.name,
  description: msg.description,
  icon: VisitorsIcon,
  grantAccess: (claims) =>
    (claims?.roles as string[]).includes?.(VISITORS_ROLE.name),
};
