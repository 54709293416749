import React, { FC, useState } from "react";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { theme } from "../../../constants";

import { ThemeContext, INITIAL_STATE, ThemeContextState } from "./context";

/**
 * This function returns the theme object to use based on
 * the given theme type.
 *
 * @param themeType The theme type to get.
 */
const getTheme = (themeType: ThemeContextState["themeType"]) => {
  switch (themeType) {
    case "light":
    default:
      return theme;
  }
};

/**
 * The theme provider wraps children with a MUI theme provider
 * with the selected theme.
 */
export const ThemeProvider: FC = ({ children }) => {
  const [themeType, setThemeType] = useState(INITIAL_STATE.themeType);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider
        value={{
          themeType,
          setThemeType,
        }}
      >
        <MuiThemeProvider theme={getTheme(themeType)}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};
