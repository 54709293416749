import React, { FC } from "react";
import { useTheme, lighten } from "@material-ui/core";

import { Color } from "../../../constants";

export interface LogoProps {
  className?: string;

  /**
   * If this is `true`, only the icon portion of the logo will be rendered.
   */
  icon?: boolean;

  /**
   * Invert the colour of the text to be more suitable for dark backgrounds.
   */
  inverted?: boolean;
}

/**
 * Renders the logo.
 */
export const Logo: FC<LogoProps> = ({ className, icon, inverted }) => {
  const theme = useTheme();

  const logoStyle = { fill: Color.CCCV_LIGHT_BLUE };
  const textStyle = {
    fill: inverted ? lighten(Color.TEXT_SECONDARY, 0.3) : theme.palette.common.white,
  };

  if (icon) {
    return (
      <svg
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 260 281"
      >
        <g>
          <g>
            <polygon
              style={logoStyle}
              points="160.2,99.9 160.2,30.1 130,0 130,130 260,130 229.9,99.9"
            />
            <polygon
              style={logoStyle}
              points="99.9,160.1 99.9,229.9 130,260 130,130 0,130 30.2,160.1"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1133 281"
    >
      <g>
        <g>
          <path
            style={textStyle}
            d="M208.7,236.4c11.6,0,21.1-4.6,28.4-13.8l15.1,15.6c-12,13.5-26.1,20.3-42.4,20.3s-29.7-5.1-40.2-15.4 c-10.5-10.3-15.8-23.3-15.8-38.9s5.4-28.8,16.1-39.3c10.7-10.5,23.9-15.7,39.4-15.7c17.3,0,31.9,6.6,43.6,19.8l-14.7,16.6 c-7.5-9.3-16.7-14-27.9-14c-8.4-0.2-16.6,2.9-22.8,8.7c-6.3,5.8-9.5,13.6-9.5,23.5s3,17.7,8.9,23.7 C192.7,233.3,200.5,236.5,208.7,236.4z"
          />
          <path
            style={textStyle}
            d="M317.9,236.4c11.6,0,21.1-4.6,28.4-13.8l15.1,15.6c-12,13.5-26.1,20.3-42.4,20.3s-29.7-5.1-40.2-15.4 c-10.5-10.3-15.8-23.3-15.8-38.9s5.4-28.8,16.1-39.3c10.7-10.5,23.9-15.7,39.4-15.7c17.3,0,31.9,6.6,43.6,19.8l-14.7,16.6 c-7.5-9.3-16.7-13.9-27.8-13.9c-8.4-0.2-16.6,2.9-22.8,8.7c-6.3,5.8-9.5,13.6-9.5,23.5s3,17.7,8.9,23.7 C302,233.3,309.8,236.5,317.9,236.4z"
          />
          <path
            style={textStyle}
            d="M427.1,236.4c11.6,0,21.1-4.6,28.4-13.8l15.1,15.6c-12,13.5-26.1,20.3-42.4,20.3c-16.3,0-29.7-5.1-40.2-15.4 c-10.5-10.3-15.8-23.3-15.8-38.9s5.4-28.8,16.1-39.3c10.7-10.5,23.9-15.7,39.4-15.7c17.3,0,31.9,6.6,43.6,19.8l-14.7,16.6 c-7.5-9.3-16.7-13.9-27.8-13.9c-8.4-0.2-16.6,2.9-22.8,8.7c-6.3,5.8-9.5,13.6-9.5,23.5s3,17.7,8.9,23.7 C411.2,233.3,419,236.5,427.1,236.4z"
          />
          <path
            style={textStyle}
            d="M526.8,218.8l27-67.3h25.6l-42.5,105.8h-20l-42.5-105.8h25.6L526.8,218.8z"
          />
        </g>
        <g>
          <polygon
            style={logoStyle}
            points="160.1,99.9 160.1,30.1 130,0 130,130 260,130 229.9,99.9"
          />
          <polygon
            style={logoStyle}
            points="99.9,160.1 99.9,229.9 130,260 130,130 0,130 30.1,160.1"
          />
        </g>
        <g>
          <path
            style={textStyle}
            d="M596,230.8c0-3.7,0.6-7.2,1.9-10.5c1.3-3.3,3.1-6.2,5.5-8.7c2.4-2.5,5.3-4.5,8.6-5.9 c3.4-1.4,7.1-2.2,11.3-2.2c5.4,0,10.1,1.2,14.1,3.6c3.9,2.4,6.9,5.6,8.8,9.5l-10.8,3.4c-1.3-2.2-3-3.9-5.1-5.2 c-2.2-1.2-4.5-1.9-7.2-1.9c-2.2,0-4.3,0.4-6.2,1.3c-1.9,0.9-3.6,2.1-5,3.7c-1.4,1.6-2.5,3.4-3.3,5.6c-0.8,2.2-1.2,4.5-1.2,7.2 c0,2.6,0.4,4.9,1.3,7.1c0.8,2.2,2,4.1,3.4,5.7c1.4,1.6,3.1,2.9,5,3.8c1.9,0.9,3.9,1.4,6.1,1.4c1.3,0,2.7-0.2,4-0.6 c1.3-0.4,2.6-0.9,3.7-1.6c1.1-0.7,2.1-1.4,2.9-2.3c0.8-0.9,1.5-1.8,1.9-2.8l10.9,3.2c-1.7,4-4.7,7.2-8.8,9.7 c-4.1,2.5-8.9,3.8-14.5,3.8c-4.1,0-7.8-0.7-11.2-2.2c-3.4-1.5-6.2-3.5-8.6-6c-2.4-2.5-4.2-5.4-5.5-8.7 C596.7,238,596,234.5,596,230.8z"
          />
          <path
            style={textStyle}
            d="M679,258.2c-4.2,0-7.9-0.7-11.2-2.2s-6.2-3.5-8.5-5.9c-2.4-2.5-4.2-5.4-5.4-8.7c-1.3-3.3-1.9-6.8-1.9-10.4 c0-3.6,0.6-7.1,1.9-10.4c1.3-3.3,3.1-6.2,5.5-8.7c2.4-2.5,5.2-4.5,8.6-5.9c3.3-1.5,7-2.2,11.1-2.2c4.1,0,7.8,0.7,11.1,2.2 c3.3,1.5,6.2,3.5,8.6,5.9c2.4,2.5,4.2,5.4,5.5,8.7c1.3,3.3,1.9,6.8,1.9,10.4c0,3.6-0.6,7.1-1.9,10.4c-1.3,3.3-3.1,6.2-5.5,8.7 c-2.4,2.5-5.2,4.5-8.6,5.9C686.8,257.5,683.1,258.2,679,258.2z M663.2,231c0,2.6,0.4,4.9,1.2,7.1c0.8,2.2,1.9,4,3.3,5.6 c1.4,1.6,3.1,2.8,5,3.7c1.9,0.9,4,1.4,6.2,1.4s4.3-0.5,6.2-1.4c1.9-0.9,3.6-2.2,5-3.8c1.4-1.6,2.5-3.5,3.3-5.6 c0.8-2.1,1.2-4.5,1.2-7.1c0-2.5-0.4-4.8-1.2-7c-0.8-2.2-1.9-4.1-3.3-5.7c-1.4-1.6-3.1-2.9-5-3.8c-1.9-0.9-4-1.4-6.2-1.4 c-2.2,0-4.2,0.5-6.1,1.4c-1.9,0.9-3.6,2.2-5,3.8c-1.4,1.6-2.5,3.5-3.4,5.7C663.7,226.2,663.2,228.5,663.2,231z"
          />
          <path
            style={textStyle}
            d="M796.6,257.2h-11.1v-29.5c0-4.9-0.8-8.5-2.4-10.8c-1.6-2.3-4-3.4-7.1-3.4c-3.2,0-6,1.2-8.6,3.5 c-2.6,2.3-4.5,5.3-5.6,9v31.3h-11.1v-29.5c0-5-0.8-8.6-2.4-10.8c-1.6-2.3-3.9-3.4-7-3.4c-3.2,0-6,1.1-8.7,3.4 c-2.6,2.3-4.5,5.3-5.6,9v31.4H716v-52.7h10.1v10.6c2.1-3.7,4.8-6.5,8.2-8.5c3.4-2,7.3-3,11.6-3c4.4,0,7.8,1.1,10.3,3.4 c2.5,2.3,4.1,5.1,4.7,8.6c2.2-3.9,5-6.9,8.4-8.9c3.4-2,7.2-3.1,11.5-3.1c3.1,0,5.7,0.6,7.7,1.8c2,1.2,3.7,2.7,4.9,4.7 c1.2,2,2,4.2,2.5,6.8c0.5,2.6,0.7,5.3,0.7,8.2V257.2z"
          />
          <path
            style={textStyle}
            d="M890.1,257.2H879v-29.5c0-4.9-0.8-8.5-2.4-10.8c-1.6-2.3-4-3.4-7.1-3.4c-3.2,0-6,1.2-8.6,3.5 c-2.6,2.3-4.5,5.3-5.6,9v31.3h-11.1v-29.5c0-5-0.8-8.6-2.4-10.8c-1.6-2.3-3.9-3.4-7-3.4c-3.2,0-6,1.1-8.7,3.4 c-2.6,2.3-4.5,5.3-5.6,9v31.4h-11.1v-52.7h10.1v10.6c2.1-3.7,4.8-6.5,8.2-8.5c3.4-2,7.3-3,11.6-3c4.4,0,7.8,1.1,10.3,3.4 c2.5,2.3,4.1,5.1,4.7,8.6c2.2-3.9,5-6.9,8.4-8.9c3.4-2,7.2-3.1,11.5-3.1c3.1,0,5.7,0.6,7.7,1.8c2,1.2,3.7,2.7,4.9,4.7 c1.2,2,2,4.2,2.5,6.8c0.5,2.6,0.7,5.3,0.7,8.2V257.2z"
          />
          <path
            style={textStyle}
            d="M919.1,258.2c-5.5,0-9.7-1.8-12.5-5.4c-2.8-3.6-4.2-9-4.2-16.1v-32.3h11.1v30c0,9.5,3.4,14.2,10.1,14.2 c3.2,0,6.1-1,9-2.9c2.8-1.9,5-4.8,6.6-8.5v-32.9h11.1v39.6c0,1.3,0.2,2.2,0.7,2.7c0.4,0.5,1.2,0.8,2.4,0.9v9.5 c-1.1,0.2-2.1,0.3-2.8,0.4c-0.7,0.1-1.4,0.1-2.1,0.1c-2,0-3.7-0.6-5.1-1.7c-1.4-1.1-2.2-2.5-2.2-4.3l-0.2-5.2 c-2.4,3.8-5.4,6.7-9.2,8.7C927.8,257.3,923.6,258.2,919.1,258.2z"
          />
          <path
            style={textStyle}
            d="M1011.6,257.2h-11.1v-29.5c0-5-0.8-8.6-2.4-10.8c-1.6-2.3-3.9-3.4-7-3.4c-1.6,0-3.2,0.3-4.8,0.9 c-1.6,0.6-3.1,1.5-4.5,2.6c-1.4,1.1-2.7,2.4-3.8,3.9c-1.1,1.5-2,3.2-2.6,5v31.4h-11.1v-52.7h10.1v10.6c2-3.6,4.9-6.4,8.8-8.4 c3.8-2,8.1-3.1,12.7-3.1c3.2,0,5.7,0.6,7.8,1.7c2,1.1,3.6,2.7,4.8,4.7c1.2,2,2,4.3,2.5,6.9c0.5,2.6,0.7,5.3,0.7,8.2V257.2z"
          />
          <path
            style={textStyle}
            d="M1024.5,195.8v-12.2h11.1v12.2H1024.5z M1024.5,257.2v-52.7h11.1v52.7H1024.5z"
          />
          <path
            style={textStyle}
            d="M1078.2,254.5c-1.4,0.7-3.4,1.4-5.8,2.3c-2.5,0.8-5.1,1.3-8,1.3c-1.7,0-3.4-0.2-4.9-0.7 c-1.5-0.5-2.9-1.2-4.1-2.1c-1.2-0.9-2.1-2.2-2.8-3.7c-0.7-1.5-1-3.3-1-5.5v-33h-7v-8.6h7v-17.3h11.1v17.3h11.5v8.6h-11.5v29.4 c0.1,1.9,0.7,3.2,1.8,4.1c1,0.8,2.3,1.3,3.9,1.3c1.5,0,3-0.3,4.4-0.8c1.4-0.5,2.5-1,3.1-1.3L1078.2,254.5z"
          />
          <path
            style={textStyle}
            d="M1087.7,269.6c1,0.2,2,0.4,3,0.5c1,0.1,1.8,0.2,2.4,0.2c0.9,0,1.6-0.2,2.3-0.6c0.6-0.4,1.3-1,2-2 c0.7-0.9,1.4-2.3,2.1-4s1.5-3.9,2.5-6.6l-21.7-52.7h11.4l16.3,42.9l14.5-42.9h10.5l-23.9,64.9c-1.1,3-2.9,5.6-5.6,7.6 c-2.7,2-6.1,3.1-10.2,3.1c-0.8,0-1.6-0.1-2.5-0.2c-0.9-0.1-1.8-0.3-2.9-0.7V269.6z"
          />
        </g>
      </g>
    </svg>
  );
};
