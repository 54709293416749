/**
 * This function loads the data for the specified locale.
 *
 * @param locale The locale to load messages for.
 */
export const loadLocaleData = async (locale: string) => {
  switch (locale) {
    case "en":
      return import("../lang/en-compiled.json");
  }
};
