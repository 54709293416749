import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import UsersIcon from "@material-ui/icons/People";
import InvitesIcon from "@material-ui/icons/Send";
import VerificationsIcon from "@material-ui/icons/HowToReg";

import AppDrawerLinkButton from "../../../components/core/AppDrawerLinkButton";

import msg from "./messages";
import useStyles from "./styles";

export interface UsersDrawerProps {}

/**
 * Menu drawer for the users application.
 */
export const UsersDrawer: FC<UsersDrawerProps> = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <List className={classes.list}>
      <Typography
        variant="button"
        component="div"
        color="textSecondary"
        className={classes.title}
      >
        <FormattedMessage {...msg.title} />
      </Typography>
      <AppDrawerLinkButton
        to="/secure/users"
        selected={pathname === "/secure/users"}
        icon={<UsersIcon />}
        primary={<FormattedMessage {...msg.users} />}
      />
      <AppDrawerLinkButton
        to="/secure/users/invites"
        selected={pathname === "/secure/users/invites"}
        icon={<InvitesIcon />}
        primary={<FormattedMessage {...msg.invites} />}
      />
      <AppDrawerLinkButton
        to="/secure/users/verifications"
        selected={pathname === "/secure/users/verifications"}
        icon={<VerificationsIcon />}
        primary={<FormattedMessage {...msg.verifications} />}
      />
    </List>
  );
};
