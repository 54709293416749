import { makeStyles, createStyles, Theme, lighten } from "@material-ui/core/styles";

import { Color } from "../../../constants";

export default makeStyles<Theme>((theme) =>
  createStyles({
    menuList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    menuPaper: {
      marginTop: theme.spacing(7),
    },
    avatar: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      width: theme.spacing(8),
      height: theme.spacing(8),
      outline: "none",
    },
    text: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    avatarColor: {
      backgroundColor: Color.TEXT_SECONDARY_CONTRAST,
    },
    skeletonColor: {
      backgroundColor: "#37434836",
    },
    divider: {
      width: "100%",
    },
    accountButton: {
      borderRadius: 60,
    },
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    padding: {
      padding: theme.spacing(2),
    },
    footerPadding: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    separator: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    link: {
      "color": Color.TEXT_SECONDARY,
      "&:hover": {
        color: lighten(Color.TEXT_SECONDARY, 0.3),
      },
    },
  })
);
