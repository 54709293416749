import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "SystemDrawer.title",
    defaultMessage: "System",
  },
  logs: {
    id: "SystemDrawer.logs",
    defaultMessage: "Logs",
  },
  emails: {
    id: "SystemDrawer.emails",
    defaultMessage: "Email settings",
  },
});
