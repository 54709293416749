import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { APP_BAR_HEIGHT_SPACING } from "../../../components/secure/AppBar";

export const DRAWER_WIDTH_SPACING = 30;

export default makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      width: theme.spacing(DRAWER_WIDTH_SPACING),
      border: "none",
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up("md")]: {
        backgroundColor: "transparent",
        marginTop: theme.spacing(APP_BAR_HEIGHT_SPACING),
        transition: `${theme.transitions.create("transform", {
          easing: theme.transitions.easing.easeInOut,
          duration: 150,
        })} !important`,
      },
    },
  })
);
