import { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { useAuth } from "../../../contexts/firebase/FirebaseContext";

import {
  AuthLoadingState,
  AuthenticationState,
  AuthorisationState,
} from "../../../state/FirebaseAuthState";

/**
 * Captures when the Firebase auth state changes and
 * saves the user's details in Recoil state.
 */
export const CaptureFirebaseAuthState: FC = () => {
  const auth = useAuth();

  const setLoading = useSetRecoilState(AuthLoadingState);
  const setUser = useSetRecoilState(AuthenticationState);
  const setClaims = useSetRecoilState(AuthorisationState);

  // Attach listener for capturing auth state changes
  useEffect(() => {
    setLoading(true);
    auth?.onAuthStateChanged(async (userInfo) => {
      if (!userInfo) {
        setUser(null);
        setClaims(null);
      } else {
        // Whenever this effect runs, make sure we refresh the user's
        // id token to retrieve the most up to date claims
        const { claims } = await userInfo.getIdTokenResult(true);
        setClaims(claims);
        setUser({
          uid: userInfo.uid,
          email: userInfo.email,
          displayName: userInfo.displayName,
          photoURL: userInfo.photoURL,
          phoneNumber: userInfo.phoneNumber,
          providerId: userInfo.providerId,
        });
      }
      setLoading(false);
    });
  }, [auth, setLoading, setUser, setClaims]);

  return null;
};
