import { defineMessages } from "react-intl";
import UsersIcon from "@material-ui/icons/PeopleAlt";

import { ADMIN_ROLE } from "../../constants";

import { Application } from "./types";

const msg = defineMessages({
  name: {
    id: "UsersApp.name",
    defaultMessage: "Users",
  },
  description: {
    id: "UsersApp.description",
    defaultMessage: "Manage user access",
  },
});

export const UsersApp: Application = {
  id: "users",
  name: msg.name,
  description: msg.description,
  icon: UsersIcon,
  grantAccess: (claims) => (claims?.roles as string[]).includes?.(ADMIN_ROLE.name),
};
