import React, { FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { useAppDrawerState } from "../../../state/AppUIState";

import { useMobileBreakpoint } from "../../../hooks/breakpoint";

import useStyles from "./styles";

export interface AppDrawerLinkButtonProps {
  /**
   * The location the button links to.
   */
  to: string;

  /**
   * The icon to show on the button.
   */
  icon?: React.ReactNode;

  /**
   * The primary text on the button.
   */
  primary?: React.ReactNode;

  /**
   * The secondary text on the button.
   */
  secondary?: React.ReactNode;

  /**
   * Show the button as selected.
   */
  selected?: boolean;
}

/**
 * Renders a link button. Styled for use in the App Drawer.
 *
 * Closes the drawer on click when on mobile breakpoints.
 */
export const AppDrawerLinkButton: FC<AppDrawerLinkButtonProps> = ({
  icon,
  primary,
  secondary,
  selected,
  to,
}) => {
  const classes = useStyles();
  const mobile = useMobileBreakpoint();
  const [, closeDrawer] = useAppDrawerState();
  return (
    <ListItem
      button
      component={Link}
      to={to}
      onClick={mobile ? closeDrawer : undefined}
      className={clsx(classes.button, {
        [classes.selected]: selected,
      })}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
};
