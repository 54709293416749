import React, { FC, useState, useEffect } from "react";
import equal from "fast-deep-equal/es6";
import algoliasearch, { SearchClient } from "algoliasearch/lite";

import { RefUtil, CLogger, EnvUtils } from "../../../utils";

import { useCurrentUser } from "../../../state/FirebaseAuthState";

import { useCurrentUserProfile } from "../../../hooks/auth";

import { useFunction } from "../../../contexts/firebase/FirebaseContext";

import { SearchContext } from "./context";

import { SearchKey } from "../../../../../types";

type KeyState = "not loaded" | "loading" | "loaded" | "error";

const log = CLogger.category("contexts.core.SearchContext");

/**
 * Attempts to provide a valid search API key for the
 * currently authenticated user.
 */
export const SearchProvider: FC = ({ children }) => {
  const generateKey = useFunction("algolia-generateKey");
  const user = useCurrentUser();
  const [profile] = useCurrentUserProfile();

  const [searchKey, setSearchKey] = useState<string>();
  const [searchKeyData, setSearchKeyData] = useState<SearchKey>();
  const [state, setState] = useState<KeyState>("not loaded");
  const [client, setClient] = useState<SearchClient>();

  // Retrieve key from Firestore
  useEffect(() => {
    if (!user) return;
    if (!profile) return;
    if (state !== "not loaded") return;

    // Make sure user is verified
    const { claims } = profile;
    if (typeof claims.verified !== "number") return;

    // Retrieve the key data from Firestore
    if (user && state === "not loaded") {
      setState("loading");
      RefUtil.searchKey(user.uid)
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          if (data) setSearchKeyData(snapshot.data());
          setState("loaded");
        })
        .catch((error) => {
          log({
            level: "error",
            message: `Error when retreiving search key. ${error.message}`,
          });
          setState("error");
        });
    }
  }, [user, profile, state, setSearchKeyData, setState]);

  // Once the key has been loaded, ensure we have a valid key.
  // If the key is not valid, try to generate a new one.
  useEffect(() => {
    if (searchKey) return;
    if (!profile) return;
    if (!generateKey) return;
    if (state !== "loaded") return;

    const { claims } = profile;

    // Check if we need a new key
    let requiresNewKey = false;
    if (!searchKeyData) requiresNewKey = true;
    else if (new Date() > searchKeyData.expires.toDate()) requiresNewKey = true;
    else if (!equal(claims, searchKeyData?.claims)) requiresNewKey = true;

    if (!requiresNewKey) {
      // Set search key if ready
      setSearchKey(searchKeyData!.key);
      log({
        level: "debug",
        message: "Search key ready for use.",
      });
    } else {
      // Otherwise, request a new key be generated
      setState("loading");
      generateKey()
        .then(() => {
          // Reset the state to trigger a fetch from Firestore
          // after the key genreation is complete
          setState("not loaded");
          log({
            level: "debug",
            message: "Generated new search key.",
          });
        })
        .catch((error) => {
          log({
            level: "error",
            message: `Error when requesting search key generation. ${error.message}`,
          });
          setState("error");
        });
    }
  }, [
    generateKey,
    profile,
    state,
    searchKey,
    searchKeyData,
    setSearchKey,
    setState,
  ]);

  // Create an Algolia client when we have a search key
  useEffect(() => {
    if (!searchKey) return;
    setClient(algoliasearch(EnvUtils.get("ALGOLIA_APP_ID"), searchKey));
  }, [searchKey, setClient]);

  return (
    <SearchContext.Provider value={{ searchKey, client }}>
      {children}
    </SearchContext.Provider>
  );
};
