import React, { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import MUIAppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";

import {
  AppBarColorState,
  AppBarTransparentState,
  useAppDrawerState,
} from "../../../state/AppUIState";

import { useCurrentUserProfile } from "../../../hooks/auth";
import { useMenuAnchor } from "../../../hooks/menu";

import AccountAvatarButton from "../../../components/account/AccountAvatarButton";
import AccountMenu from "../../../components/account/AccountMenu";
import Logo from "../../../components/core/Logo";

import useStyles from "./styles";

export interface AppBarProps {
  /**
   * Disable the button to toggle the menu.
   */
  disableMenuButton?: boolean;
}

/**
 * Renders the app bar at the top of the page with the
 * current user's account avatar and notification button.
 */
export const AppBar: FC<AppBarProps> = ({ disableMenuButton }) => {
  const [profile] = useCurrentUserProfile();

  const [userAnchor, openUser, closeUser] = useMenuAnchor();
  const [, , toggleDrawer] = useAppDrawerState();

  const top = useScrollTrigger({ disableHysteresis: true });
  const color = useRecoilValue(AppBarColorState);
  const transparent = useRecoilValue(AppBarTransparentState);

  const classes = useStyles({
    // Always use a solid color when the user scrolls down
    color: !top ? color : "light",
    transparent: !top ? transparent : false,
  });

  return (
    <MUIAppBar
      variant={!top ? "outlined" : "elevation"}
      elevation={!top ? 0 : 4}
      className={classes.appBar}
    >
      <div className={classes.buttons}>
        <IconButton
          className={clsx(classes.menu, classes.button, {
            [classes.disabled]: disableMenuButton,
          })}
          disabled={disableMenuButton}
          onClick={toggleDrawer}
          title="menu"
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.home, {
            [classes.homeShift]: !disableMenuButton,
          })}
          component={Link}
          to="/secure"
          title="home"
        >
          <Logo icon className={classes.logo} />
        </IconButton>
      </div>
      <div className={classes.buttons}>
        <IconButton className={classes.button} title="notifications">
          <NotificationsIcon />
        </IconButton>
        <AccountAvatarButton profile={profile} onClick={openUser} />
        <AccountMenu
          profile={profile}
          open={Boolean(userAnchor)}
          anchorEl={userAnchor}
          onClose={closeUser}
        />
      </div>
    </MUIAppBar>
  );
};
