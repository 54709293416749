import React, { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";

import {
  useAuthLoading,
  useCurrentUser,
  useCurrentUserClaims,
} from "../../../state/FirebaseAuthState";

import { SearchProvider } from "../../../contexts/core/SearchContext";

import { useCurrentUserProfile } from "../../../hooks/auth";

import Loading from "../../../components/core/Loading";
import AppShell from "../../../components/secure/AppShell";

import SignInPage from "../../../pages/secure/SignInPage/loadable";
import SignOutPage from "../../../pages/secure/SignOutPage/loadable";
import UnverifiedPage from "../../../pages/secure/UnverifiedPage/loadable";
import AcceptTermsPage from "../../../pages/secure/AcceptTermsPage/loadable";

import msg from "./messages";

export interface SecureProps {}

/**
 * This component protects secure content routes by checking the current
 * user's auth state. When there is no user, a sign in page is shown.
 *
 * When the user is not verified or needs to accept the terms of use, the
 * appropriate screen is shown.
 *
 * If all checks pass, only then is the route content shown.
 */
export const Secure: FC<SecureProps> = () => {
  const location = useLocation();
  const loading = useAuthLoading();
  const user = useCurrentUser();
  const claims = useCurrentUserClaims();
  const [profile] = useCurrentUserProfile();

  // Reload the page if the verified claim changes
  useEffect(() => {
    if (profile && claims?.verified !== profile?.claims.verified)
      window.location.reload();
  }, [claims, profile]);

  // If the user is requesting a sign out, show the sign out page
  if (location.pathname === "/secure/exit") {
    return <SignOutPage />;
  }

  // Show loading screen when user auth is loading
  if (
    loading ||
    // The user could have no profile if they are signing in for the first time
    (user && !profile)
  ) {
    return <Loading caption={<FormattedMessage {...msg.signingIn} />} />;
  }

  // If we have no user, render sign in page
  if (!user) {
    return <SignInPage />;
  }

  // Render unverified page if the user does not have the verified role
  if (!claims?.verified) {
    return <UnverifiedPage />;
  }

  // Render accept temrs page if the user has not accepted the terms of use
  if (!claims?.accepted_terms) {
    return <AcceptTermsPage />;
  }

  // Render content if all secure checks pass
  return (
    <SearchProvider>
      <AppShell>
        <Outlet />
      </AppShell>
    </SearchProvider>
  );
};
