import React, { FC } from "react";

import FadeIn from "../../../components/core/FadeIn";

import useStyles from "./styles";

export interface PageLayoutProps {
  /**
   * If `true`, a `<FadeIn/>` component will be wrapped around
   * the layout so that the page will fade in.
   */
  fade?: boolean;
}

/**
 * This component distributes children vertically.
 */
export const PageLayout: FC<PageLayoutProps> = ({ children, fade }) => {
  const classes = useStyles();
  const page = <div className={classes.layout}>{children}</div>;
  if (!fade) return page;
  return <FadeIn>{page}</FadeIn>;
};
