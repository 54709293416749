import { nanoid } from "nanoid";
import { LoggerUtil } from "./LoggerUtil";

export const uid = () => nanoid();

export const CLogger = new LoggerUtil({
  source: "frontend",
  group: true,
});

export * from "./DateUtils";
export * from "./EnvUtils";
export * from "./FirestoreUtils";
export * from "./HashingUtils";
export * from "./IntlUtils";
export * from "./LocaleUtils";
export * from "./PlaceholderUtils";
export * from "./RefUtil";
export * from "./TextUtils";
export * from "./ValidateUtils";
