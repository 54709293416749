import { sha256 } from "crypto-hash";

import { Visitor } from "../../../types";

/**
 * NOTE: This implementation should be identical across client and server.
 */
export class HashingUtils {
  /**
   * Hash a visitor's firstname, lastname and contact as
   * parameters for a unqiue identifier.
   */
  public static async hashVisitor({
    firstname,
    lastname,
    contact,
  }: Pick<Visitor, "firstname" | "lastname" | "contact">) {
    const message = {
      firstname: firstname.trim().toLowerCase(),
      lastname: lastname.trim().toLowerCase(),
      contact: contact.trim().toLowerCase(),
    };
    return sha256(JSON.stringify(message));
  }
}
