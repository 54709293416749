import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Color } from "../../../constants";

export default makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      alignSelf: "center",
      padding: theme.spacing(1),
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarColor: {
      backgroundColor: Color.TEXT_SECONDARY_CONTRAST,
    },
    skeletonColor: {
      backgroundColor: "#37434836",
    },
  })
);
