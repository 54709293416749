import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Color } from "../../../constants";

export const APP_BAR_HEIGHT_SPACING = 7;

export default makeStyles<Theme, { color: "light" | "dark"; transparent: boolean }>(
  (theme) =>
    createStyles({
      appBar: ({ transparent }) => ({
        border: "none",
        transition: theme.transitions.create("background-color"),
        color: transparent ? "inherit" : "white",
        backgroundColor: transparent ? "transparent" : theme.palette.secondary.main,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: theme.spacing(APP_BAR_HEIGHT_SPACING),
      }),
      buttons: {
        display: "flex",
        alignItems: "center",
      },
      button: ({ color = "dark" }) => ({
        padding: theme.spacing(1),
        marginRight: theme.spacing(0.5),
        transition: theme.transitions.create(["color", "opacity"]),
        color:
          color === "dark"
            ? theme.palette.action.active
            : Color.TEXT_PRIMARY_CONTRAST,
      }),
      menu: {
        position: "absolute",
      },
      disabled: {
        opacity: 0,
      },
      home: {
        padding: theme.spacing(1),
        color: Color.TEXT_PRIMARY_CONTRAST,
        transition: theme.transitions.create(["margin-left"]),
      },
      homeShift: {
        marginLeft: theme.spacing(5),
      },
      logo: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    })
);
