import { useEffect } from "react";

import { CLogger } from "../utils";

/**
 * Log an error message when the given `Error` object is present.
 */
export const useLogError = (
  message: string,
  category: string,
  error?: Error | null
) => {
  useEffect(() => {
    if (error)
      CLogger.category(category)({
        level: "error",
        message: `${message} ${error.message}`,
        data: error,
      });
  }, [message, category, error]);
};
