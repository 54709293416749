import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    list: {
      padding: theme.spacing(2),
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  })
);
