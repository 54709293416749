import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import VisitorsIcon from "@material-ui/icons/TransferWithinAStation";
import LocationIcon from "@material-ui/icons/LocationOn";
import TraceIcon from "@material-ui/icons/MyLocation";

import { UserRole } from "../../../constants";

import { useHasRole } from "../../../hooks/auth";

import AppDrawerLinkButton from "../../../components/core/AppDrawerLinkButton";

import msg from "./messages";
import useStyles from "./styles";

export interface VisitorsDrawerProps {}

/**
 * Menu drawer for the visitors application.
 */
export const VisitorsDrawer: FC<VisitorsDrawerProps> = () => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const canManage = useHasRole(UserRole.VISITORS_MANAGE);
  const canTrace = useHasRole(UserRole.VISITORS_TRACE);

  return (
    <List className={classes.list}>
      <Typography
        variant="button"
        component="div"
        color="textSecondary"
        className={classes.title}
      >
        <FormattedMessage {...msg.title} />
      </Typography>
      <AppDrawerLinkButton
        to="/secure/visitors"
        selected={pathname === "/secure/visitors"}
        icon={<VisitorsIcon />}
        primary={<FormattedMessage {...msg.tracker} />}
      />
      {canManage && (
        <AppDrawerLinkButton
          to="/secure/visitors/locations"
          selected={pathname === "/secure/visitors/locations"}
          icon={<LocationIcon />}
          primary={<FormattedMessage {...msg.locations} />}
        />
      )}
      {canTrace && (
        <AppDrawerLinkButton
          to="/secure/visitors/trace"
          selected={pathname.startsWith("/secure/visitors/trace")}
          icon={<TraceIcon />}
          primary={<FormattedMessage {...msg.trace} />}
        />
      )}
    </List>
  );
};
