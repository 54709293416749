import React, { FC } from "react";
import { useLocation } from "react-router";

import AppBar from "../../../components/secure/AppBar";
import AppDrawer from "../../../components/core/AppDrawer";
import AppDrawerContent from "../../../components/core/AppDrawerContent";
import FeatureBar from "../../../components/core/FeatureBar";
import PageLayout from "../../../components/core/PageLayout";
import Footer from "../../../components/core/Footer";

import UsersDrawer from "../../../components/users/UsersDrawer";
import SystemDrawer from "../../../components/system/SystemDrawer";
import VisitorsDrawer from "../../../components/visitors/VisitorsDrawer";

// import useStyles from "./styles";

export interface AppShellProps {}

/**
 * Renders the application shell components (app bar, drawer and footer)
 * around the main content.
 */
export const AppShell: FC<AppShellProps> = ({ children }) => {
  // const classes = useStyles();
  const { pathname } = useLocation();

  let drawer: JSX.Element | null = null;

  if (pathname.startsWith("/secure/users")) drawer = <UsersDrawer />;
  if (pathname.startsWith("/secure/system")) drawer = <SystemDrawer />;
  if (pathname.startsWith("/secure/visitors")) drawer = <VisitorsDrawer />;

  return (
    <>
      <AppBar disableMenuButton={!Boolean(drawer)} />
      <FeatureBar />
      <AppDrawer>{drawer}</AppDrawer>
      <PageLayout>
        <AppDrawerContent>{children}</AppDrawerContent>
        <Footer />
      </PageLayout>
    </>
  );
};
