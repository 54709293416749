import React, { FC, useState, useRef, useEffect } from "react";

import Fade from "@material-ui/core/Fade";

export interface FadeInProps {
  /**
   * The delay in ms before showing child components.
   * @default 0
   */
  delay?: number;

  /**
   * The time in ms for the suspended component to fade in.
   * @default 500
   */
  fade?: number;
}

/**
 * This component waits (and renders `null`) for the specified
 * amount of time before rendering its children. Pass a time in
 * ms to the `fade` prop to fade in the children over the specified
 * amount of time.
 */
export const FadeIn: FC<FadeInProps> = ({ children, fade = 500, delay = 0 }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const mountedRef = useRef(true);

  // Set the fade in pararmters
  useEffect(() => {
    if (delay === 0) {
      setFadeIn(true);
    } else {
      setTimeout(() => {
        if (mountedRef.current) setFadeIn(true);
      }, delay);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [setFadeIn, delay]);

  return (
    <Fade in={fadeIn} timeout={fade}>
      <div>{children}</div>
    </Fade>
  );
};
