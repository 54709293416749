import { makeStyles, createStyles, Theme, lighten } from "@material-ui/core/styles";

import { Color } from "../../../constants";

export default makeStyles<Theme>((theme) =>
  createStyles({
    footer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: lighten(Color.TEXT_SECONDARY, 0.3),
    },
    link: {
      color: lighten(Color.TEXT_SECONDARY, 0.2),
    },
    spacer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);
