import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { HELP_EMAIL } from "../constants";

/**
 * Functions for resolving XML tags in the `<FormattedMessage />`
 * component to support rich text formatting using `react-intl`.
 */
export class IntlUtils {
  /**
   * Wraps the given value in a `<b>` element.
   */
  public static b(chunk: string) {
    return <b>{chunk}</b>;
  }

  /**
   * Accepts an email address and returns `<a>` with a mailto: href attribute.
   */
  public static email(chunk: string) {
    return <Link href={`mailto:${chunk}`}>{chunk}</Link>;
  }

  /**
   * Wraps a value with `<a>` with a mailto: href attribute to the help email.
   */
  public static helpEmail(chunk: string) {
    return <Link href={`mailto:${HELP_EMAIL}`}>{chunk}</Link>;
  }

  /**
   * Creates a link to the root path.
   */
  public static home(chunk: string) {
    return (
      <Link component={RouterLink} to="/">
        {chunk}
      </Link>
    );
  }

  /**
   * Creates a link to /contact.
   */
  public static contact(chunk: string) {
    return (
      <Link component={RouterLink} to="/contact">
        {chunk}
      </Link>
    );
  }

  /**
   * Creates a link to /terms.
   */
  public static terms(chunk: string) {
    return (
      <Link component={RouterLink} to="/terms">
        {chunk}
      </Link>
    );
  }

  /**
   * Creates a link to /privacy.
   */
  public static privacy(chunk: string) {
    return (
      <Link component={RouterLink} to="/privacy">
        {chunk}
      </Link>
    );
  }

  /**
   * Creates a link to cccv.org.au.
   */
  public static cccv(chunk: string) {
    return <Link href="https://cccv.org.au">{chunk}</Link>;
  }
}
