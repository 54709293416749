import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import LogsIcon from "@material-ui/icons/Dvr";
import EmailsIcon from "@material-ui/icons/EmailOutlined";

import AppDrawerLinkButton from "../../../components/core/AppDrawerLinkButton";

import msg from "./messages";
import useStyles from "./styles";

export interface SystemDrawerProps {}

/**
 * Menu drawer for the system application.
 */
export const SystemDrawer: FC<SystemDrawerProps> = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <List className={classes.list}>
      <Typography
        variant="button"
        component="div"
        color="textSecondary"
        className={classes.title}
      >
        <FormattedMessage {...msg.title} />
      </Typography>
      <AppDrawerLinkButton
        to="/secure/system/logs?levels=info,warn,error"
        selected={pathname === "/secure/system/logs"}
        icon={<LogsIcon />}
        primary={<FormattedMessage {...msg.logs} />}
      />
      <AppDrawerLinkButton
        to="/secure/system/emails"
        selected={pathname === "/secure/system/emails"}
        icon={<EmailsIcon />}
        primary={<FormattedMessage {...msg.emails} />}
      />
    </List>
  );
};
