import React, { FC, useState, useEffect } from "react";
import { IntlProvider, IntlConfig } from "react-intl";
import { useRecoilValue } from "recoil";

import { loadLocaleData } from "../../../utils";

import { LocaleState } from "../../../state/LocaleState";

/**
 * The language provider provides Intl messages to the application
 * and loads the language file based on the given locale if it is
 * available.
 *
 * The selected locale is stored in `LocaleAtom` state. When this
 * value is changed, the messages provided by Intl will be updated.
 */
export const LanguageProvider: FC = ({ children }) => {
  const locale = useRecoilValue(LocaleState);
  const [messages, setMessages] = useState<IntlConfig["messages"]>({});

  // Load the messages for the selected locale
  useEffect(() => {
    if (!locale) return;
    if (process.env.NODE_ENV === "test") return;
    loadLocaleData(locale).then((messages) => setMessages(messages?.default ?? {}));
  }, [locale, setMessages]);

  return (
    <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
      {children}
    </IntlProvider>
  );
};
