import { useContext, useState, useEffect } from "react";
import { SearchIndex } from "algoliasearch/lite";

import { SearchContext } from "./context";

import { IndexName } from "../../../../../types";

/**
 * Gets the current search key.
 */
export const useSearchKey = () => {
  const context = useContext(SearchContext);
  return context.searchKey;
};

/**
 * Create a reference to a search index with the give name.
 * @param indexName The name of the index.
 */
export const useSearchIndex = (indexName?: IndexName) => {
  const { client } = useContext(SearchContext);
  const [index, setIndex] = useState<SearchIndex>();

  // Create the index reference
  useEffect(() => {
    if (!indexName) return;
    if (!client) return;
    setIndex(client.initIndex(indexName));
  }, [indexName, client, setIndex]);

  return index;
};
