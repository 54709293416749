import firebase from "firebase/app";

import { ColRef, DocRef } from ".";

import {
  UserProfile,
  Log,
  Invite,
  EmailTemplate,
  Notification,
  UnsubscribePreferences,
  SearchKey,
  IndexItem,
  Visitor,
  Visit,
  HintRecord,
  VisitLocation,
  VisitorReportRequest,
} from "../../../types";

export class RefUtil {
  private static collection<T>(id: string): ColRef<T> {
    return firebase.firestore().collection(id) as ColRef<T>;
  }

  // Collections

  public static get users() {
    return RefUtil.collection<UserProfile>("users");
  }

  public static get invites() {
    return RefUtil.collection<Invite>("invites");
  }

  public static get logs() {
    return RefUtil.collection<Log>("logs");
  }

  public static get emailTemplates() {
    return RefUtil.collection<EmailTemplate>("emailTemplates");
  }

  public static get notifications() {
    return RefUtil.collection<Notification>("notifications");
  }

  public static get unsubscribes() {
    return RefUtil.collection<UnsubscribePreferences>("unsubscribes");
  }

  public static get searchKeys() {
    return RefUtil.collection<SearchKey>("searchKeys");
  }

  public static index(indexId: string) {
    return RefUtil.collection("index")
      .doc(indexId)
      .collection("items") as ColRef<IndexItem>;
  }

  public static get visitors() {
    return RefUtil.collection<Visitor>("visitors");
  }

  public static get visits() {
    return RefUtil.collection<Visit>("visits");
  }

  public static get visitLocations() {
    return RefUtil.collection<VisitLocation>("visitLocations");
  }

  public static get visitorReports() {
    return RefUtil.collection<VisitorReportRequest>("visitorReports");
  }

  // Documents

  public static user(id: string) {
    return RefUtil.users.doc(id);
  }

  public static userHint(userId: string, hintId: string) {
    return RefUtil.users
      .doc(userId)
      .collection("hints")
      .doc(hintId) as DocRef<HintRecord>;
  }

  public static invite(id: string) {
    return RefUtil.invites.doc(id);
  }

  public static newInvite() {
    return RefUtil.invites.doc();
  }

  public static emailTemplate(id: string) {
    return RefUtil.emailTemplates.doc(id);
  }

  public static notification(id: string) {
    return RefUtil.notifications.doc(id);
  }

  public static newNotification() {
    return RefUtil.notifications.doc();
  }

  public static unsubscribe(id: string) {
    return RefUtil.unsubscribes.doc(id);
  }

  public static searchKey(id: string) {
    return RefUtil.searchKeys.doc(id);
  }

  public static visitor(id: string) {
    return RefUtil.visitors.doc(id);
  }

  public static newVisitor() {
    return RefUtil.visitors.doc();
  }

  public static visit(id: string) {
    return RefUtil.visits.doc(id);
  }

  public static newVisit() {
    return RefUtil.visits.doc();
  }

  public static newVisitLocation() {
    return RefUtil.visitLocations.doc();
  }

  public static newVisitorReport() {
    return RefUtil.visitorReports.doc();
  }
}
