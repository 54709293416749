import { atom } from "recoil";

/**
 * This atom keeps track of whether the app bar is transparent.
 */
export const AppBarTransparentState = atom({
  key: "AppBarTransparentState",
  default: true,
});

/**
 * This atom keeps track of the app bar color theme.
 */
export const AppBarColorState = atom<"light" | "dark">({
  key: "AppBarColorState",
  default: "dark",
});

/**
 * This atom keeps track of whether the app drawer is open.
 */
export const AppDrawerOpenState = atom({
  key: "AppDrawerOpenState",
  default: false,
});

/**
 * This atom keeps track of whether the feature bar is transparent.
 */
export const FeatureBarTransparentState = atom({
  key: "FeatureBarTransparentState",
  default: true,
});
