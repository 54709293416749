import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import { APP_NAME } from "../../../constants";

import PageLayout from "../../../components/core/PageLayout";
import Logo from "../../../components/core/Logo";
import FadeIn from "../../../components/core/FadeIn";

import msg from "./messages";
import useStyles from "./styles";

export interface LoadingProps {
  /**
   * The caption to render below the loading logo.
   */
  caption?: React.ReactNode;
}

/**
 * Render a full page loading logo with a caption.
 */
export const Loading: FC<LoadingProps> = ({ caption }) => {
  const classes = useStyles();
  return (
    <FadeIn>
      <PageLayout>
        <div />
        <div className={classes.content}>
          <Logo className={classes.logo} icon />
          <div className={classes.caption}>
            <LinearProgress className={classes.progress} />
            <Typography variant="body1" color="textSecondary">
              <b>{APP_NAME}</b>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {caption ?? <FormattedMessage {...msg.caption} />}
            </Typography>
          </div>
        </div>
        <div />
        <div />
      </PageLayout>
    </FadeIn>
  );
};
