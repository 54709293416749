import firebase from "firebase/app";
import { atom } from "recoil";

/**
 * This atom keeps track whether the auth state is loading.
 */
export const AuthLoadingState = atom<boolean>({
  key: "AuthLoadingState",
  default: false,
});

/**
 * This atom keeps track of the user's Firebase authentication state.
 */
export const AuthenticationState = atom<firebase.UserInfo | null>({
  key: "AuthenticationState",
  default: null,
});

/**
 * This atom keeps track of the user's Firebase authorisation state.
 */
export const AuthorisationState = atom<Record<string, any> | null>({
  key: "AuthorisationState",
  default: null,
});
