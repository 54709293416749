import { uid } from ".";

export class PlaceholderUtils {
  /**
   * Generate a list of random ids to use when generating placeholders.
   *
   * @param amount The number of ids to generate.
   */
  public static generate(amount: number) {
    const ids: string[] = [];
    for (let x = 0; x < amount; x++) {
      ids.push(uid());
    }
    return ids;
  }
}
