import React, { FC } from "react";
import { useRecoilValue } from "recoil";

import Drawer from "@material-ui/core/Drawer";

import { AppDrawerOpenState, useAppDrawerState } from "../../../state/AppUIState";

import { useDesktopBreakpoint } from "../../../hooks/breakpoint";

import useStyles from "./styles";

export interface AppDrawerProps {}

/**
 * Renders a responsive drawer. Children are rendered as
 * drawer contents.
 *
 * This component's open state can be controlled through
 * the `AppDrawerOpenState` atom.
 */
export const AppDrawer: FC<AppDrawerProps> = ({ children }) => {
  const classes = useStyles();
  const desktop = useDesktopBreakpoint();
  const open = useRecoilValue(AppDrawerOpenState);
  const [, closeDrawer] = useAppDrawerState();
  return (
    <Drawer
      classes={{
        paper: classes.paper,
      }}
      onClose={closeDrawer}
      variant={desktop ? "persistent" : "temporary"}
      open={open}
    >
      {children}
    </Drawer>
  );
};
