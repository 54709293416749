import { makeStyles, createStyles, Theme, lighten } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      borderRadius: theme.shape.borderRadius,
    },
    selected: {
      "backgroundColor": theme.palette.divider,
      "&:hover": {
        backgroundColor: lighten(theme.palette.divider, 0.2),
      },
    },
  })
);
