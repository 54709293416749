import React, { FC, useState, useEffect } from "react";
import firebase from "firebase/app";

import { EnvUtils, CLogger } from "../../../utils";

import { FirebaseContext } from "./context";

const log = CLogger.category("contexts.firebase.FirebaseProvider");

/**
 * Retrieves the Firebase config from the environment
 * variables and initialises Firebase.
 */
export const FirebaseProvider: FC = ({ children }) => {
  const [initialised, setInitialised] = useState(false);

  // Initialise Firebase when we have a valid configuration
  useEffect(() => {
    if (!initialised) {
      // Check if Firebase is already initialised
      if (firebase.apps.length > 0) {
        setInitialised(true);
        return;
      }

      const firebaseJSON = EnvUtils.get("FIREBASE_CONFIG");

      try {
        // Parse Firebase config
        const config = JSON.parse(firebaseJSON);

        // Initialise the app
        firebase.initializeApp(config);
        setInitialised(true);
        log({
          level: "debug",
          message: "Initialised Firebase.",
        });
      } catch (error) {
        log({
          level: "error",
          message: `Failed to parse Firebase config. ${error.message}\n ${error.stack}`,
          data: { firebaseJSON },
        });
      }
    }
  }, [initialised, setInitialised]);

  return (
    <FirebaseContext.Provider value={{ initialised }}>
      {children}
    </FirebaseContext.Provider>
  );
};
