import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Color } from "../../../constants";

import { FeatureBarProps } from "./FeatureBar";

export default makeStyles<Theme, FeatureBarProps & { transparent: boolean }>(
  (theme) =>
    createStyles({
      featureBar: ({ height = 280, transparent }) => ({
        position: "absolute",
        background: transparent ? "transparent" : Color.CCCV_DARK_BLUE,
        transition: theme.transitions.create("background-color", {
          duration: 150,
        }),
        width: "100%",
        height,
        zIndex: -1,
      }),
    })
);
