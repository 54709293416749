import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import Menu, { MenuProps } from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/core/Skeleton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { useSignOut } from "../../../hooks/auth";

import msg from "./messages";
import useStyles from "./styles";

import { UserProfile } from "../../../../../types";

export interface AccountMenuProps extends Pick<MenuProps, "open" | "anchorEl"> {
  /**
   * Callback to close the menu.
   */
  onClose?: () => void;

  /**
   * The user to show in the account menu.
   */
  profile?: UserProfile;
}

/**
 * Show the account menu with the current's details and links
 * to manage their account and sign out.
 */
export const AccountMenu: FC<AccountMenuProps> = ({
  open,
  anchorEl,
  onClose,
  profile,
}) => {
  const classes = useStyles();
  const signOut = useSignOut();
  const { photoURL, name, email } = profile ?? {};
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorReference={open && !anchorEl ? "none" : "anchorEl"}
      PaperProps={{
        className: classes.menuPaper,
      }}
      MenuListProps={{
        disablePadding: true,
        classes: {
          root: classes.menuList,
        },
      }}
    >
      <Avatar src={photoURL} className={clsx(classes.avatar, classes.avatarColor)}>
        {photoURL
          ? null
          : name?.[0] ?? (
              <Skeleton
                variant="circular"
                className={clsx(classes.avatar, classes.skeletonColor)}
              />
            )}
      </Avatar>
      <Typography variant="body1" className={classes.text}>
        <b>{name}</b>
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.text}>
        {email}
      </Typography>
      <div className={clsx(classes.container, classes.padding)}>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.accountButton}
          component={RouterLink}
          to="/secure/account"
          onClick={onClose}
        >
          <FormattedMessage {...msg.settings} />
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div className={clsx(classes.container, classes.padding)}>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          className={classes.signOutButton}
          onClick={() => {
            onClose?.();
            signOut();
          }}
        >
          <FormattedMessage {...msg.signOut} />
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div className={clsx(classes.container, classes.footerPadding)}>
        <Link
          className={classes.link}
          variant="caption"
          onClick={onClose}
          component={RouterLink}
          to="/privacy"
        >
          <FormattedMessage {...msg.privacy} />
        </Link>
        <span className={classes.separator}>•</span>
        <Link
          className={classes.link}
          variant="caption"
          onClick={onClose}
          component={RouterLink}
          to="/terms"
        >
          <FormattedMessage {...msg.terms} />
        </Link>
      </div>
    </Menu>
  );
};
