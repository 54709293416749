import { VisitorsApp } from "./VisitorsApp";
import { UsersApp } from "./UsersApp";
import { SystemApp } from "./SystemApp";

export * from "./types";

/**
 * All applications in the system.
 */
export const LIST = [VisitorsApp, UsersApp, SystemApp];
