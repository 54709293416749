import React, { FC, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { UserRole } from "../../../constants";

import { useCurrentUserClaims } from "../../../state/FirebaseAuthState";
import { useOpenDrawerOnDesktop } from "../../../state/AppUIState";

import RestrictedPage from "../../../pages/core/RestrictedPage/loadable";

export interface RestrictProps {
  /**
   * A claim or list of claims that must be `true` in the
   * user's claims for the route contents to be rendered.
   * If no claims are given, nothing is returned.
   */
  roles?: UserRole | UserRole[];

  /**
   * Set to `true` to oepn the app drawer when this component mounts.
   */
  openDrawer?: boolean;
}

type RestrictState = "pending" | "approved" | "denied";

/**
 * This component checks that the user has the required
 * claims before showing contents of the route. Otherwise,
 * the user is shown a restricted page instead.
 */
export const Restrict: FC<RestrictProps> = ({ roles, openDrawer }) => {
  useOpenDrawerOnDesktop(!openDrawer);

  const claims = useCurrentUserClaims();
  const [state, setState] = useState<RestrictState>("pending");

  // When claims have loaded, check that every claim is `true`
  useEffect(() => {
    if (!claims) return;
    if (!roles) return;
    const required = typeof roles === "string" ? [roles] : roles;
    const denied =
      typeof claims.verified !== "number" ||
      required.some((c) => !(claims.roles as string[])?.includes(c));
    setState(denied ? "denied" : "approved");
  }, [roles, claims, setState]);

  if (state === "pending") return null;
  else if (state === "denied") return <RestrictedPage />;
  return <Outlet />;
};
