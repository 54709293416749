import firebase from "firebase/app";
import "firebase/firestore";

export type DocRef<T> = firebase.firestore.DocumentReference<T>;
export type ColRef<T> = firebase.firestore.CollectionReference<T>;

export class FirestoreUtils {
  /**
   * Generates a firebase.firestore document id.
   */
  public static docId() {
    return firebase.firestore().collection("document").doc().id;
  }

  /**
   * Create a Firestore server timestamp sentinel.
   */
  public static timestamp(date?: Date): firebase.firestore.Timestamp {
    if (date) return firebase.firestore.Timestamp.fromDate(date);
    return firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  }

  /**
   * Create a Firestore timestamp relative to the current time in days.
   * @param days The number of days ahead (or behind if negative) to create the timestamp.
   */
  public static timestampInDays(days: number): firebase.firestore.Timestamp {
    const date = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
    return FirestoreUtils.timestamp(date);
  }

  /**
   * Create an increment sentinel.
   *
   * @param amount The amount to increment by.
   */
  public static increment(amount: number): number {
    return (firebase.firestore.FieldValue.increment(amount) as unknown) as number;
  }

  /**
   * Create an array union sentinel to push an object into
   * a field on a document.
   *
   * @param elements List of elements to push.
   */
  public static push<T = firebase.firestore.FieldValue>(elements: T): T[] {
    return (firebase.firestore.FieldValue.arrayUnion(elements) as unknown) as T[];
  }

  /**
   * Create a sentinel to delete a field from a document.
   */
  public static delete<T = firebase.firestore.FieldValue>(): T {
    return (firebase.firestore.FieldValue.delete() as unknown) as T;
  }

  /**
   * Create a reference to a document.
   *
   * @param path The path to the document.
   */
  public static createDocRef<T>(path: string): DocRef<T> {
    return firebase.firestore().doc(path) as DocRef<T>;
  }

  /**
   * Create a reference to a collection.
   *
   * @param path The path to the document.
   */
  public static createColRef<T>(path: string): ColRef<T> {
    return firebase.firestore().collection(path) as ColRef<T>;
  }
}
