import React, { FC } from "react";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDayjs from "@material-ui/lab/AdapterDayjs";

import { UserRole } from "./constants";

import { ThemeProvider } from "./contexts/core/ThemeContext";
import { LanguageProvider } from "./contexts/core/LanguageContext";
import { FirebaseProvider } from "./contexts/firebase/FirebaseContext";

import ErrorBoundary from "./components/core/ErrorBoundary";
import DocumentTitle from "./components/core/DocumentTitle";
import Secure from "./components/secure/Secure";
import Restrict from "./components/secure/Restrict";
import CaptureAuth from "./components/secure/CaptureFirebaseAuthState";

import CrashPage from "./pages/core/CrashPage/loadable";
import NotFoundPage from "./pages/core/NotFoundPage/loadable";
import TermsPage from "./pages/core/TermsPage/loadable";
import PrivacyPage from "./pages/core/PrivacyPage/loadable";
import ContactPage from "./pages/core/ContactPage/loadable";
import AccountPage from "./pages/secure/AccountPage/loadable";
import DashboardPage from "./pages/secure/DashboardPage/loadable";
import UsersPage from "./pages/users/UsersPage/loadable";
import InvitesPage from "./pages/users/InvitesPage/loadable";
import VerificationsPage from "./pages/users/VerificationsPage/loadable";
import LogsPage from "./pages/system/LogsPage/loadable";
import EmailSettingsPage from "./pages/system/EmailSettingsPage/loadable";
import VisitorsPage from "./pages/visitors/VisitorsPage/loadable";
import VisitLocationsPage from "./pages/visitors/VisitLocationsPage/loadable";
import VisitorTracePage from "./pages/visitors/VisitorTracePage/loadable";
import VisitorReportPage from "./pages/visitors/VisitorReportPage/loadable";

const App: FC = () => (
  <HelmetProvider>
    <ThemeProvider>
      <RecoilRoot>
        <LanguageProvider>
          <ErrorBoundary fallback={(error) => <CrashPage error={error} />}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FirebaseProvider>
                <DocumentTitle />
                <CaptureAuth />
                <SnackbarProvider>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </SnackbarProvider>
              </FirebaseProvider>
            </LocalizationProvider>
          </ErrorBoundary>
        </LanguageProvider>
      </RecoilRoot>
    </ThemeProvider>
  </HelmetProvider>
);

const AppRoutes: FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to="secure" />} />
    <Route path="secure" element={<Secure />}>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/users" element={<Restrict roles={UserRole.ADMIN} openDrawer />}>
        <Route path="/" element={<UsersPage />} />
        <Route path="invites" element={<InvitesPage />} />
        <Route path="verifications" element={<VerificationsPage />} />
      </Route>
      <Route path="/system" element={<Restrict roles={UserRole.ADMIN} openDrawer />}>
        <Route path="/" element={<Navigate to="logs?levels=info,warn,error" />} />
        <Route path="/logs" element={<LogsPage />} />
        <Route path="/emails" element={<EmailSettingsPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/visitors"
        element={<Restrict roles={UserRole.VISITORS} openDrawer />}
      >
        <Route path="/" element={<VisitorsPage />} />
        <Route
          path="/locations"
          element={<Restrict roles={UserRole.VISITORS_MANAGE} />}
        >
          <Route path="/" element={<VisitLocationsPage />} />
        </Route>
        <Route path="/trace" element={<Restrict roles={UserRole.VISITORS_TRACE} />}>
          <Route path="/" element={<VisitorTracePage />} />
          <Route path="/:reportId" element={<VisitorReportPage />} />
        </Route>
      </Route>
    </Route>
    <Route path="contact" element={<ContactPage />} />
    <Route path="terms" element={<TermsPage />} />
    <Route path="privacy" element={<PrivacyPage />} />
    <Route path="*" element={<NotFoundPage full />} />
  </Routes>
);

export default App;
