import React, { FC } from "react";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/core/Skeleton";

import useStyles from "./styles";

import { UserProfile } from "../../../../../types";

export interface AccountAvatarButtonProps {
  /**
   * The user to show the avatar for.
   */
  profile?: UserProfile;

  /**
   * Callback for when the button is clicked.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * Shows the given user's avatar as a button.
 */
export const AccountAvatarButton: FC<AccountAvatarButtonProps> = ({
  profile,
  onClick,
}) => {
  const classes = useStyles();
  const { photoURL, name } = profile ?? {};
  return (
    <IconButton className={classes.button} onClick={onClick} title="account">
      <Avatar src={photoURL} className={clsx(classes.avatar, classes.avatarColor)}>
        {photoURL
          ? null
          : name?.[0] ?? (
              <Skeleton
                variant="circular"
                className={clsx(classes.avatar, classes.skeletonColor)}
              />
            )}
      </Avatar>
    </IconButton>
  );
};
