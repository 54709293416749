import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "100%",
    },
    logo: {
      height: 100,
      width: 100,
    },
    progress: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    caption: {
      position: "absolute",
      bottom: theme.spacing(-10),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  })
);
