import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { DocumentTitleState } from "./atoms";

/**
 * Set the document title state. The title is reset
 * when the hook unmounts.
 */
export const useSetDocumentTitle = (title: string = "") => {
  const setTitle = useSetRecoilState(DocumentTitleState);
  useEffect(() => {
    setTitle(title);
    return () => setTitle("");
  }, [title, setTitle]);
};
