import { uid } from ".";

import { Log, LogLevel } from "../../../types";

/**
 * Logger utility that prints log messages to the console.
 * If a function is provided to upload the log to a database,
 * the function will be called each time a message is logged.
 */
export class LoggerUtil {
  /**
   * The source this logger will be creating log messages for.
   */
  private source: string;

  /**
   * The levels which the log message should be displayed.
   */
  private logLevels: LogLevel[];

  /**
   * The levels which the log messages should be uploaded
   * if an `upload` method is provided.
   */
  private uploadLevels: LogLevel[];

  /**
   * If this is `true`, logged messages will be grouped
   * using `console.group` when printed to the console.
   *
   * Note that this option will only be effective when
   * the logger is in a browser environment.
   */
  private group: boolean;

  /**
   * The function used to upload the log to a database.
   */
  private upload?: (log: Log) => Promise<any>;

  /**
   * Method to start a log group.
   */
  private logGroup: (...label: any[]) => void;

  /**
   * Method to end a log group.
   */
  private logGroupEnd: () => void;

  /**
   * A mapping of log methods to use for each log level.
   */
  private log: Record<LogLevel, (...data: any[]) => void>;

  constructor(
    options: {
      source: string;
      logLevels?: LogLevel[];
      uploadLevels?: LogLevel[];
      group?: boolean;
      upload?: typeof LoggerUtil.prototype.upload;
      logGroup?: typeof LoggerUtil.prototype.logGroup;
      logGroupEnd?: typeof LoggerUtil.prototype.logGroupEnd;
    },
    log?: Partial<typeof LoggerUtil.prototype.log>
  ) {
    const {
      source,
      logLevels,
      uploadLevels,
      group,
      logGroup,
      logGroupEnd,
      upload,
    } = options;
    const { debug, info, warn, error } = log ?? {};
    this.source = source;
    this.logLevels = logLevels ?? ["debug", "info", "warn", "error"];
    this.uploadLevels = uploadLevels ?? ["debug", "info", "warn", "error"];
    this.group = group ?? false;
    this.logGroup = logGroup ?? console.group;
    this.logGroupEnd = logGroupEnd ?? console.groupEnd;
    this.upload = upload;
    this.log = {
      debug: debug ?? console.log,
      info: info ?? console.log,
      warn: warn ?? console.warn,
      error: error ?? console.error,
    };
  }

  /**
   * Create a debug log message.
   */
  public debug(
    options: Pick<Log, "category" | "message"> & Partial<Pick<Log, "tags" | "data">>
  ) {
    this.createLog({ ...options, level: "debug" });
  }

  /**
   * Create an info log message.
   */
  public info(
    options: Pick<Log, "category" | "message"> & Partial<Pick<Log, "tags" | "data">>
  ) {
    this.createLog({ ...options, level: "info" });
  }

  /**
   * Create a warn log message.
   */
  public warn(
    options: Pick<Log, "category" | "message"> & Partial<Pick<Log, "tags" | "data">>
  ) {
    this.createLog({ ...options, level: "warn" });
  }

  /**
   * Create an error log message.
   */
  public error(
    options: Pick<Log, "category" | "message"> & Partial<Pick<Log, "tags" | "data">>
  ) {
    this.createLog({ ...options, level: "error" });
  }

  /**
   * Create a log message.
   */
  public createLog(
    options: Pick<Log, "category" | "level" | "message"> &
      Partial<Pick<Log, "tags" | "data">>
  ) {
    const { category } = options;
    this.category(category)(options);
  }

  /**
   * This function creates a closure that will create a log
   * message with the specified category.
   *
   * @param category The category to create the closure with.
   */
  public category(category: Log["category"]) {
    return (
      options: Pick<Log, "level" | "message"> & Partial<Pick<Log, "tags" | "data">>
    ) => {
      const { level, message, tags, data } = options;

      // Create log message
      const log: Log = {
        id: uid(),
        date: new Date() as any,
        source: this.source,
        category,
        level,
        message,
        tags: tags ?? [],
        data: data ?? {},
      };

      // Upload log message
      if (this.uploadLevels.includes(level))
        this.upload?.(log).catch((error) => {
          console.error(`Failed to upload log.`, log);
          console.error(error);
        });

      // Do not print the log message to console if we don't need to
      if (!this.logLevels.includes(level) || process.env.NODE_ENV === "test") return;

      // Print log message
      if (this.group) this.logGroup(`[${level}] ${category}`);
      this.log[level](message);
      if (data) this.log[level](data);
      if (this.group) this.logGroupEnd();
    };
  }
}
